import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Token/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition } from "vue";
const _hoisted_1 = { class: "flex flex-col" };
const _hoisted_2 = { class: "my-4" };
import {
  SpotOrderSide
} from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import { Modal, TradeField } from "@/types";
import { TRADE_FORM_PRICE_ROUNDING_MODE } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "TokenForm",
  props: {
    isLoading: Boolean,
    isBaseAmount: Boolean,
    market: {
      type: Object,
      default: () => null
    },
    worstPriceWithSlippage: {
      type: Object,
      required: true
    }
  },
  emits: ["update:isBaseAmount", "update:market", "update:amount"],
  setup(__props, { emit }) {
    const props = __props;
    const route = useRoute();
    const spotStore = useSpotStore();
    const bankStore = useBankStore();
    const modalStore = useModalStore();
    const tokenStore = useTokenStore();
    const formValues = useFormValues();
    const animationCount = ref(0);
    const { takerFeeRate } = useTradeFee(computed(() => props.market));
    const { accountBalancesWithToken } = useBalance();
    const { tradableSlugMap, tradableTokensMap, getMarketsForQuoteDenom } = useConvertFormatter();
    const isBuy = computed(() => orderType.value === SpotOrderSide.Buy);
    const { value: baseTokenDenom, setValue: setBaseTokenDenom } = useStringField({
      name: TradeField.BaseDenom
    });
    const { value: quoteTokenDenom, setValue: setQuoteTokenDenom } = useStringField(
      {
        name: TradeField.QuoteDenom
      }
    );
    const baseTokens = computed(
      () => tradableTokensMap.value[baseTokenDenom.value] || []
    );
    const quoteTokens = computed(
      () => tradableTokensMap.value[quoteTokenDenom.value] || []
    );
    const baseTokensWithBalance = computed(() => {
      return baseTokens.value.map((baseToken) => {
        const accountBalance = accountBalancesWithToken.value.find(
          (accountBalance2) => {
            return accountBalance2.denom === baseToken.denom;
          }
        );
        return {
          token: baseToken,
          denom: baseToken.denom,
          balance: (accountBalance == null ? void 0 : accountBalance.availableMargin) || "0",
          usdPrice: tokenStore.tokenUsdPrice(baseToken.coinGeckoId)
        };
      });
    });
    const quoteTokensWithBalance = computed(() => {
      return quoteTokens.value.map((quoteToken) => {
        const accountBalance = accountBalancesWithToken.value.find(
          (accountBalance2) => {
            return accountBalance2.denom === quoteToken.denom;
          }
        );
        return {
          token: quoteToken,
          denom: quoteToken.denom,
          balance: (accountBalance == null ? void 0 : accountBalance.availableMargin) || "0",
          usdPrice: tokenStore.tokenUsdPrice(quoteToken.coinGeckoId)
        };
      });
    });
    const { value: orderType, setValue: setOrderType } = useStringField({
      name: TradeField.OrderType,
      initialValue: SpotOrderSide.Buy
    });
    onMounted(() => {
      if (!bankStore.hasEnoughInjForGas) {
        modalStore.openModal({ type: Modal.InsufficientInjForGas });
      }
      setMarketFromQuery();
    });
    function handleUpdateMarket() {
      let market = [
        ...spotStore.markets,
        ...spotStore.usdcConversionModalMarkets
      ].find(({ baseDenom, quoteDenom }) => {
        return baseDenom === baseTokenDenom.value && quoteDenom === quoteTokenDenom.value;
      });
      if (!market) {
        market = getMarketsForQuoteDenom({
          baseTokenDenom: baseTokenDenom.value,
          quoteTokenDenom: quoteTokenDenom.value
        });
        setBaseTokenDenom(market.baseDenom);
        setQuoteTokenDenom(market.quoteDenom);
      }
      if (market) {
        emit("update:market", market);
      }
    }
    function toggleOrderType() {
      setOrderType(isBuy.value ? SpotOrderSide.Sell : SpotOrderSide.Buy);
    }
    function handleSwap() {
      animationCount.value = animationCount.value + 1;
      emit("update:isBaseAmount", !props.isBaseAmount);
      formValues.value[TradeField.BaseAmount] = "";
      formValues.value[TradeField.QuoteAmount] = "";
      toggleOrderType();
    }
    function updateAmount({
      amount,
      isBaseAmount
    }) {
      emit("update:amount", { amount, isBaseAmount });
    }
    function handleMaxBaseAmountChange({ amount }) {
      formValues.value[TradeField.BaseAmount] = amount;
      updateAmount({ amount, isBaseAmount: true });
    }
    function handleMaxQuoteAmountChange({ amount }) {
      const amountInBigNumber = new BigNumberInBase(amount);
      const feeRateToDeduct = amountInBigNumber.times(takerFeeRate.value);
      const amountDeductFee = amountInBigNumber.minus(feeRateToDeduct);
      const amountDeductFeeToFixed = amountDeductFee.toFixed(
        props.market.priceDecimals,
        TRADE_FORM_PRICE_ROUNDING_MODE
      );
      formValues.value[TradeField.QuoteAmount] = amountDeductFeeToFixed;
      emit("update:amount", { amount: amountDeductFeeToFixed, isBaseAmount: false });
    }
    function setMarketFromQuery() {
      const { to, from } = route.query;
      const querySlug = `${from}-${to}`;
      const { market, orderType: orderType2 } = tradableSlugMap.value[querySlug.toLowerCase()] || tradableSlugMap.value["usdt-inj"];
      if (!market) {
        return;
      }
      emit("update:market", market);
      setOrderType(orderType2);
      setBaseTokenDenom(market.baseDenom);
      setQuoteTokenDenom(market.quoteDenom);
    }
    watch(
      () => props.worstPriceWithSlippage,
      () => {
        emit("update:amount", {
          amount: props.isBaseAmount ? formValues.value[TradeField.BaseAmount] : formValues.value[TradeField.QuoteAmount],
          isBaseAmount: props.isBaseAmount
        });
      }
    );
    return (_ctx, _cache) => {
      const _component_AppSelectToken = __nuxt_component_0;
      const _component_BaseIcon = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_Transition, {
          name: _unref(isBuy) ? "fade-up" : "fade-down",
          mode: "out-in"
        }, {
          default: _withCtx(() => {
            var _a;
            return [
              (_openBlock(), _createElementBlock("div", {
                key: `animation-${_unref(animationCount)}`,
                class: _normalizeClass([_unref(isBuy) ? "order-first" : "order-last"])
              }, [
                _createVNode(_component_AppSelectToken, {
                  denom: _unref(quoteTokenDenom),
                  "onUpdate:denom": [
                    _cache[0] || (_cache[0] = ($event) => _isRef(quoteTokenDenom) ? quoteTokenDenom.value = $event : null),
                    handleUpdateMarket
                  ],
                  "amount-field-name": _unref(TradeField).QuoteAmount,
                  required: _unref(isBuy),
                  disabled: __props.isLoading,
                  "hide-max": !_unref(isBuy),
                  "max-decimals": (_a = __props.market) == null ? void 0 : _a.quantityDecimals,
                  options: _unref(baseTokensWithBalance),
                  "onUpdate:amount": updateAmount,
                  "onUpdate:max": handleMaxQuoteAmountChange
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`trade.convert.${_unref(isBuy) ? "youPay" : "youReceive"}`)), 1)
                  ]),
                  _: 1
                }, 8, ["denom", "amount-field-name", "required", "disabled", "hide-max", "max-decimals", "options"])
              ], 2))
            ];
          }),
          _: 1
        }, 8, ["name"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseIcon, {
            name: "arrow-up-down",
            class: "mx-auto w-6 h-6",
            onClick: handleSwap
          })
        ]),
        _createVNode(_Transition, {
          name: _unref(isBuy) ? "fade-down" : "fade-up",
          mode: "out-in"
        }, {
          default: _withCtx(() => {
            var _a;
            return [
              (_openBlock(), _createElementBlock("div", {
                key: `animation-${_unref(animationCount)}`,
                class: _normalizeClass([_unref(isBuy) ? "order-last" : "order-first"])
              }, [
                _createVNode(_component_AppSelectToken, {
                  denom: _unref(baseTokenDenom),
                  "onUpdate:denom": [
                    _cache[1] || (_cache[1] = ($event) => _isRef(baseTokenDenom) ? baseTokenDenom.value = $event : null),
                    handleUpdateMarket
                  ],
                  "amount-field-name": _unref(TradeField).BaseAmount,
                  disabled: __props.isLoading,
                  required: !_unref(isBuy),
                  "hide-max": _unref(isBuy),
                  "max-decimals": (_a = __props.market) == null ? void 0 : _a.quantityDecimals,
                  options: _unref(quoteTokensWithBalance),
                  "onUpdate:amount": updateAmount,
                  "onUpdate:max": handleMaxBaseAmountChange
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`trade.convert.${_unref(isBuy) ? "youReceive" : "youPay"}`)), 1)
                  ]),
                  _: 1
                }, 8, ["denom", "amount-field-name", "disabled", "required", "hide-max", "max-decimals", "options"])
              ], 2))
            ];
          }),
          _: 1
        }, 8, ["name"])
      ]);
    };
  }
});
