import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "w-full" };
const _hoisted_2 = { class: "max-auto w-full" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { key: 2 };
const _hoisted_6 = {
  key: 2,
  class: "text-red-500 text-sm mt-4"
};
const _hoisted_7 = {
  key: 3,
  class: "text-red-500 text-sm mt-4"
};
const _hoisted_8 = { class: "mr-1" };
import { Modal, TradeField } from "@/types";
import { tradeErrorMessages } from "@/app/client/utils/validation/trade";
export default /* @__PURE__ */ _defineComponent({
  __name: "Submit",
  props: {
    isBuy: Boolean,
    amount: {
      type: String,
      default: ""
    },
    executionPrice: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    status: {
      type: Object,
      required: true
    }
  },
  emits: ["form:submit"],
  setup(__props, { emit }) {
    const props = __props;
    const router = useRouter();
    const bankStore = useBankStore();
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    const formErrors = useFormErrors();
    const formValues = useFormValues();
    const { insufficientLiquidity, highDeviation } = useSpotError({
      formValues,
      executionPrice: computed(() => props.executionPrice),
      isBuy: computed(() => props.isBuy),
      market: computed(() => props.market)
    });
    const hasFormErrors = computed(
      () => Object.keys(formErrors.value).filter(
        (key) => ![TradeField.SlippageTolerance].includes(key)
      ).length > 0
    );
    const isSubmitDisabled = computed(() => {
      return hasFormErrors.value || props.amount === "" || !bankStore.hasEnoughInjForGas || insufficientLiquidity.value;
    });
    const hasInsufficientBalance = computed(
      () => Object.values(formErrors.value).includes(
        tradeErrorMessages.insufficientBalance()
      )
    );
    function handleConnect() {
      modalStore.openModal({ type: Modal.Connect });
    }
    function submit() {
      emit("form:submit");
    }
    function handleNavigation() {
      if (modalStore.modals[Modal.ConvertUsdc]) {
        modalStore.closeModal(Modal.ConvertUsdc);
      }
      router.push({ name: "account" });
    }
    return (_ctx, _cache) => {
      var _a, _b;
      const _component_AppButton = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        !_unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_AppButton, {
          key: 0,
          lg: "",
          class: "w-full bg-blue-500 text-blue-900 font-semibold",
          onClick: handleConnect
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("trade.convert.connect_wallet")), 1)
          ]),
          _: 1
        })) : (_openBlock(), _createBlock(_component_AppButton, {
          key: 1,
          class: "w-full bg-blue-500 text-blue-900 font-semibold",
          lg: "",
          disabled: _unref(isSubmitDisabled),
          status: __props.status,
          onClick: submit
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              !_unref(bankStore).hasEnoughInjForGas ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("insufficientGas.insufficientGas")), 1)) : _unref(insufficientLiquidity) ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("trade.convert.insufficient_liquidity")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("trade.convert.convert")), 1))
            ])
          ]),
          _: 1
        }, 8, ["disabled", "status"])),
        _unref(highDeviation) ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("trade.execution_price_far_away_from_last_traded_price")), 1)) : _createCommentVNode("", true),
        _unref(hasInsufficientBalance) ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("trade.convert.insufficient_balance_verbose", {
            symbol: __props.isBuy ? (_a = __props.market) == null ? void 0 : _a.quoteToken.symbol : (_b = __props.market) == null ? void 0 : _b.baseToken.symbol
          })), 1),
          _createElementVNode("span", {
            class: "text-blue-600",
            onClick: handleNavigation
          }, _toDisplayString(_ctx.$t("trade.convert.goToAccount")), 1)
        ])) : _createCommentVNode("", true)
      ]);
    };
  }
});
