import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Convert/SlippageSelector.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Convert/TokenForm.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Convert/Summary.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Convert/Submit.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "max-w-90% w-[448px] mx-auto p-6 bg-gray-850 rounded-lg" };
const _hoisted_2 = { class: "mb-6 flex items-center justify-between" };
const _hoisted_3 = { class: "font-bold text-lg" };
import { SpotOrderSide } from "@injectivelabs/sdk-ui-ts";
import { Status, StatusType } from "@injectivelabs/utils";
import { TradeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "convert",
  setup(__props) {
    const router = useRouter();
    const bankStore = useBankStore();
    const spotStore = useSpotStore();
    const exchangeStore = useExchangeStore();
    const { t } = useLang();
    const { $onError } = useNuxtApp();
    const { success } = useNotifications();
    const { resetForm, values: formValues } = useForm();
    const isBaseAmount = ref(false);
    const market = ref();
    const status = reactive(new Status(StatusType.Loading));
    const fetchStatus = reactive(new Status(StatusType.Idle));
    const submitStatus = reactive(new Status(StatusType.Idle));
    const { updateAmountFromBase, worstPrice, worstPriceWithSlippage } = useSpotPrice({
      formValues: computed(() => formValues),
      market,
      isBaseAmount
    });
    const isBuy = computed(
      () => formValues[TradeField.OrderType] === SpotOrderSide.Buy
    );
    const amount = computed(
      () => isBuy.value ? formValues[TradeField.QuoteAmount] : formValues[TradeField.BaseAmount]
    );
    onMounted(() => {
      Promise.all([spotStore.init(), exchangeStore.fetchTradingRewardsCampaign()]).catch($onError).finally(() => status.setIdle());
    });
    onWalletConnected(() => {
      fetchStatus.setLoading();
      Promise.all([
        bankStore.streamBankBalance(),
        bankStore.fetchAccountPortfolio(),
        exchangeStore.fetchFeeDiscountAccountInfo()
      ]).catch($onError).finally(() => fetchStatus.setIdle());
    });
    function updateAmount({
      amount: amount2,
      isBaseAmount: isBaseAmountUpdate
    }) {
      isBaseAmount.value = isBaseAmountUpdate;
      const updatedAmount = updateAmountFromBase({
        amount: amount2,
        isBaseAmount: isBaseAmountUpdate
      });
      if (updatedAmount) {
        const field = isBaseAmountUpdate ? TradeField.QuoteAmount : TradeField.BaseAmount;
        formValues[field] = updatedAmount;
      }
    }
    function resetFormValues() {
      const isBuyState = unref(isBuy.value);
      resetForm();
      isBaseAmount.value = !isBuyState;
      formValues[TradeField.OrderType] = isBuyState ? SpotOrderSide.Buy : SpotOrderSide.Sell;
      if (market.value) {
        formValues[TradeField.BaseDenom] = market.value.baseDenom;
        formValues[TradeField.QuoteDenom] = market.value.quoteDenom;
      }
    }
    function updateUrlQuery() {
      if (!market.value) {
        return;
      }
      const { baseToken, quoteToken } = market.value;
      const baseSymbol = baseToken.symbol.toLowerCase();
      const quoteSymbol = quoteToken.symbol.toLowerCase();
      router.replace({
        query: isBuy.value ? { from: quoteSymbol, to: baseSymbol } : { from: baseSymbol, to: quoteSymbol }
      });
    }
    function handleMarketUpdate(market2) {
      resetFormValues();
      updateUrlQuery();
      fetchStatus.setLoading();
      spotStore.orderbook = {};
      Promise.all([
        spotStore.cancelTradesStream(),
        spotStore.cancelOrderbookUpdateStream(),
        spotStore.streamTrades(market2.marketId),
        spotStore.fetchOrderbook(market2.marketId),
        spotStore.streamOrderbookUpdate(market2.marketId),
        spotStore.fetchTrades({ marketId: market2.marketId })
      ]).finally(() => fetchStatus.setIdle());
    }
    function handleFormSubmit() {
      if (!market) {
        return;
      }
      submitStatus.setLoading();
      spotStore.submitMarketOrder({
        isBuy: isBuy.value,
        price: worstPriceWithSlippage.value,
        quantity: formValues[TradeField.BaseAmount],
        market: market.value
      }).then(() => {
        resetFormValues();
        success({ title: t("trade.convert.convert_success") });
      }).catch($onError).finally(() => {
        submitStatus.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_PartialsConvertSlippageSelector = __nuxt_component_0;
      const _component_PartialsConvertTokenForm = __nuxt_component_1;
      const _component_PartialsConvertSummary = __nuxt_component_2;
      const _component_PartialsConvertSubmit = __nuxt_component_3;
      const _component_AppHocLoading = __nuxt_component_4;
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        status: _unref(status),
        class: "justify-center flex h-full items-center"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("trade.convert.convert")), 1),
              _createVNode(_component_PartialsConvertSlippageSelector)
            ]),
            _createVNode(_component_PartialsConvertTokenForm, _mergeProps({
              market: _unref(market),
              "onUpdate:market": _cache[0] || (_cache[0] = ($event) => _isRef(market) ? market.value = $event : null),
              isBaseAmount: _unref(isBaseAmount),
              "onUpdate:isBaseAmount": _cache[1] || (_cache[1] = ($event) => _isRef(isBaseAmount) ? isBaseAmount.value = $event : null)
            }, {
              worstPriceWithSlippage: _unref(worstPriceWithSlippage),
              isLoading: _unref(fetchStatus).isLoading() || _unref(submitStatus).isLoading()
            }, {
              "onUpdate:amount": updateAmount,
              "onUpdate:isBuy": updateUrlQuery,
              "onUpdate:market": handleMarketUpdate
            }), null, 16, ["market", "isBaseAmount"]),
            _createVNode(_component_PartialsConvertSummary, _mergeProps({ class: "mt-4" }, {
              isBuy: _unref(isBuy),
              market: _unref(market),
              amount: _unref(amount),
              worstPriceWithSlippage: _unref(worstPriceWithSlippage),
              isLoading: _unref(fetchStatus).isLoading()
            }), null, 16),
            _unref(market) ? (_openBlock(), _createBlock(_component_PartialsConvertSubmit, _mergeProps({
              key: 0,
              class: "mt-6"
            }, {
              isBuy: _unref(isBuy),
              amount: _unref(amount),
              market: _unref(market),
              status: _unref(submitStatus),
              executionPrice: _unref(worstPrice)
            }, { "onForm:submit": handleFormSubmit }), null, 16)) : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["status"]);
    };
  }
});
