import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/Input/Numeric.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, isRef as _isRef, createSlots as _createSlots, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "text-xs font-bold uppercase tracking-widest" };
const _hoisted_2 = { class: "my-4 flex items-center gap-2" };
const _hoisted_3 = { class: "text-xs" };
const _hoisted_4 = { class: "flex items-center gap-2 max-xs:flex-wrap" };
const _hoisted_5 = { class: "flex items-center gap-2 max-xs:w-full" };
const _hoisted_6 = { class: "mx-auto leading-4" };
const _hoisted_7 = { class: "text-base capitalize" };
const _hoisted_8 = /* @__PURE__ */ _createElementVNode("span", null, "%", -1);
const _hoisted_9 = /* @__PURE__ */ _createElementVNode("span", { class: "text-gray-500" }, "%", -1);
const _hoisted_10 = {
  key: 0,
  class: "text-orange-500 mt-4 text-sm"
};
import { Dropdown, Tooltip } from "floating-vue";
import { onClickOutside } from "@vueuse/core";
import { BigNumberInBase } from "@injectivelabs/utils";
import { TradeField } from "@/types";
import { MAX_SLIPPAGE } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "SlippageSelector",
  setup(__props) {
    const popperRef = ref(null);
    const dropdownRef = ref(null);
    const hasError = ref(false);
    const isOpen = ref(false);
    const slippageList = ["0.1", "0.5", "1.0"];
    const appIconColorClass = computed(() => {
      if (slippageError.value) {
        return "text-orange-500";
      }
      return isOpen.value ? "text-blue-500" : "text-gray-500";
    });
    const {
      value: slippageTolerance,
      errors: slippageToleranceErrors,
      setValue: setSlippageToleranceValue
    } = useStringField({
      name: TradeField.SlippageTolerance,
      initialValue: "0.5",
      rule: "slippage"
    });
    const slippageError = computed(() => slippageToleranceErrors.value[0]);
    onClickOutside(
      popperRef,
      () => {
        isOpen.value = false;
      },
      {
        ignore: [dropdownRef]
      }
    );
    function toggleSlippageDropdown() {
      isOpen.value = !isOpen.value;
    }
    function checkForInvalidSlippageValue() {
      const slippageValue = new BigNumberInBase(slippageTolerance.value || 0);
      if (slippageValue.lt(0)) {
        setSlippageToleranceValue("0.5");
      }
      if (slippageValue.gt(MAX_SLIPPAGE)) {
        setSlippageToleranceValue(MAX_SLIPPAGE.toFormat(0));
      }
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_CommonInfoTooltip = __nuxt_component_1;
      const _component_AppButton = __nuxt_component_2;
      const _component_AppSelectButton = __nuxt_component_3;
      const _component_AppInputNumeric = __nuxt_component_4;
      return _openBlock(), _createBlock(_unref(Tooltip), {
        "popper-class": "tooltip",
        triggers: [],
        disabled: _unref(isOpen),
        shown: !_unref(isOpen) && _unref(slippageError) !== void 0
      }, {
        popper: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(slippageError)), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(Dropdown), {
            ref_key: "dropdownRef",
            ref: dropdownRef,
            "popper-class": "slippage",
            placement: "bottom-end",
            distance: 6,
            triggers: [],
            shown: _unref(isOpen),
            "auto-hide": false
          }, {
            popper: _withCtx(() => [
              _createElementVNode("div", {
                ref_key: "popperRef",
                ref: popperRef,
                class: "p-4 bg-gray-800 text-white"
              }, [
                _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("trade.convert.advancedSettings")), 1),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.convert.tolerance")), 1),
                  _createVNode(_component_CommonInfoTooltip, {
                    sm: "",
                    tooltip: _ctx.$t("trade.convert.tooltip")
                  }, null, 8, ["tooltip"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(slippageList, (slippage) => {
                      return _createVNode(_component_AppSelectButton, {
                        key: `slippage-selector-item-${slippage}`,
                        modelValue: _unref(slippageTolerance),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(slippageTolerance) ? slippageTolerance.value = $event : null),
                        value: slippage
                      }, {
                        default: _withCtx(({ active }) => [
                          _createVNode(_component_AppButton, {
                            sm: "",
                            class: _normalizeClass(["w-full border-blue-500 border", [
                              active ? "bg-blue-500 text-blue-900 rounded" : "text-blue-500 rounded"
                            ]])
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("span", _hoisted_7, _toDisplayString(slippage), 1),
                                _hoisted_8
                              ])
                            ]),
                            _: 2
                          }, 1032, ["class"])
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "value"]);
                    }), 64))
                  ]),
                  _createVNode(_component_AppInputNumeric, {
                    modelValue: _unref(slippageTolerance),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => _isRef(slippageTolerance) ? slippageTolerance.value = $event : null),
                    class: "ml-auto",
                    "input-classes": "text-right",
                    error: _unref(hasError),
                    sm: "",
                    onBlur: checkForInvalidSlippageValue
                  }, _createSlots({
                    addon: _withCtx(() => [
                      _hoisted_9
                    ]),
                    _: 2
                  }, [
                    _unref(slippageError) !== void 0 ? {
                      name: "prefix",
                      fn: _withCtx(() => [
                        _createVNode(_component_BaseIcon, {
                          name: "warn",
                          class: "min-w-4 text-orange-500 h-4 w-4"
                        })
                      ]),
                      key: "0"
                    } : void 0
                  ]), 1032, ["modelValue", "error"])
                ]),
                _unref(slippageError) ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_unref(slippageError)), 1)) : _createCommentVNode("", true)
              ], 512)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BaseIcon, {
                name: "gear",
                class: _normalizeClass(["h-5 w-5 hover:text-blue-500", _unref(appIconColorClass)]),
                onClick: toggleSlippageDropdown
              }, null, 8, ["class"])
            ]),
            _: 1
          }, 8, ["shown"])
        ]),
        _: 1
      }, 8, ["disabled", "shown"]);
    };
  }
});
