import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Spinner.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Convert/SummaryRow.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-end gap-2"
};
const _hoisted_2 = { class: "text-xs text-gray-500" };
const _hoisted_3 = {
  key: 1,
  class: "space-y-3 mt-2"
};
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { key: 1 };
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { key: 1 };
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import { ONE_IN_BASE } from "@/app/utils/constants";
import { TradeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Summary",
  props: {
    isBuy: Boolean,
    isLoading: Boolean,
    amount: {
      type: String,
      default: void 0
    },
    market: {
      type: Object,
      default: void 0
    },
    worstPriceWithSlippage: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    var _a, _b, _c, _d, _e, _f;
    const props = __props;
    const formValues = useFormValues();
    const { takerFeeRate } = useTradeFee(computed(() => props.market));
    const showEmpty = computed(
      () => !props.market || props.worstPriceWithSlippage.eq(0) || new BigNumberInBase(props.amount || 0).isNaN()
    );
    const fee = computed(() => {
      const quantity = new BigNumberInBase(
        formValues.value[TradeField.QuoteAmount] || 0
      );
      if (quantity.isNaN() || quantity.lte(0)) {
        return ZERO_IN_BASE;
      }
      return quantity.times(takerFeeRate.value);
    });
    const feeRateToFormat = computed(
      () => takerFeeRate.value.times(100).toFormat(2)
    );
    const priceForDisplay = computed(() => {
      if (props.isBuy) {
        const quoteAmount = props.amount || 0;
        return new BigNumberInBase(quoteAmount).dividedBy(quoteAmount).dividedBy(props.worstPriceWithSlippage);
      }
      return props.worstPriceWithSlippage;
    });
    const minimalReceived = computed(() => {
      const quantity = new BigNumberInBase(props.amount || 0);
      const feeRate = new BigNumberInBase(takerFeeRate.value);
      if (props.isBuy) {
        return quantity.dividedBy(
          props.worstPriceWithSlippage.times(ONE_IN_BASE.plus(feeRate))
        );
      }
      return quantity.times(
        props.worstPriceWithSlippage.times(ONE_IN_BASE.minus(feeRate))
      );
    });
    const inputToken = computed(() => {
      if (props.market) {
        return props.isBuy ? props.market.quoteToken : props.market.baseToken;
      }
    });
    const outputToken = computed(() => {
      if (props.market) {
        return props.isBuy ? props.market.baseToken : props.market.quoteToken;
      }
    });
    const { valueToString: feeToFormat } = useBigNumberFormatter(fee, {
      decimalPlaces: ((_a = props.market) == null ? void 0 : _a.priceDecimals) || 3,
      minimalDecimalPlaces: ((_b = props.market) == null ? void 0 : _b.priceDecimals) || 3
    });
    const { valueToFixed: priceForDisplayToFormat } = useBigNumberFormatter(
      priceForDisplay,
      {
        decimalPlaces: ((_c = props.market) == null ? void 0 : _c.priceDecimals) || 3,
        minimalDecimalPlaces: ((_d = props.market) == null ? void 0 : _d.priceDecimals) || 3
      }
    );
    const { valueToString: minimalReceivedToFormat } = useBigNumberFormatter(
      minimalReceived,
      {
        decimalPlaces: ((_e = props.market) == null ? void 0 : _e.quantityDecimals) || 3,
        minimalDecimalPlaces: ((_f = props.market) == null ? void 0 : _f.quantityDecimals) || 3
      }
    );
    return (_ctx, _cache) => {
      const _component_AppSpinner = __nuxt_component_0;
      const _component_PartialsConvertSummaryRow = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", null, [
        __props.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AppSpinner, { sm: "" }),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.convert.fetching_price")), 1)
        ])) : _unref(inputToken) && _unref(outputToken) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_PartialsConvertSummaryRow, {
            title: _ctx.$t("trade.convert.rate")
          }, {
            default: _withCtx(() => [
              _unref(showEmpty) ? (_openBlock(), _createElementBlock("span", _hoisted_4, "\u2014")) : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, " 1 " + _toDisplayString(_unref(inputToken).symbol), 1),
                _createTextVNode(" = "),
                _createElementVNode("span", null, _toDisplayString(_unref(priceForDisplayToFormat)) + " " + _toDisplayString(_unref(outputToken).symbol), 1)
              ]))
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_PartialsConvertSummaryRow, {
            title: `${_ctx.$t("trade.convert.fee")} (${_unref(feeRateToFormat)}%)`
          }, {
            default: _withCtx(() => {
              var _a2;
              return [
                _unref(showEmpty) ? (_openBlock(), _createElementBlock("span", _hoisted_6, "\u2014")) : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(feeToFormat)) + " " + _toDisplayString((_a2 = __props.market) == null ? void 0 : _a2.quoteToken.symbol), 1))
              ];
            }),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_PartialsConvertSummaryRow, {
            title: _ctx.$t("trade.convert.minimum_received")
          }, {
            default: _withCtx(() => [
              _unref(showEmpty) ? (_openBlock(), _createElementBlock("span", _hoisted_8, "\u2014")) : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(minimalReceivedToFormat)) + " " + _toDisplayString(_unref(outputToken).symbol), 1))
            ]),
            _: 1
          }, 8, ["title"])
        ])) : _createCommentVNode("", true)
      ]);
    };
  }
});
