"use strict";
import { SpotOrderSide } from "@injectivelabs/sdk-ui-ts";
export default function useConvertFormatter() {
  const spotStore = useSpotStore();
  const tradableSlugMap = computed(() => {
    return [
      ...spotStore.markets,
      ...spotStore.usdcConversionModalMarkets
    ].reduce((list, market) => {
      const reversedSlug = market.slug.split("-").reverse().join("-");
      return {
        ...list,
        [market.slug]: { orderType: SpotOrderSide.Sell, market },
        [reversedSlug]: { orderType: SpotOrderSide.Buy, market }
      };
    }, {});
  });
  const availableQuoteDenoms = computed(
    () => [...spotStore.markets, ...spotStore.usdcConversionModalMarkets].reduce(
      (tokens, market) => {
        const quoteTokenExistOnTokensList = tokens.some(
          (token) => token.denom === market.quoteDenom
        );
        return quoteTokenExistOnTokensList ? tokens : [market.quoteToken, ...tokens];
      },
      []
    )
  );
  const tradableTokensMap = computed(() => {
    return [
      ...spotStore.markets,
      ...spotStore.usdcConversionModalMarkets
    ].reduce((tokens, market) => {
      const baseTokens = tokens[market.quoteDenom] ? [...tokens[market.quoteDenom], market.baseToken] : [market.baseToken];
      const filteredAvailableQuoteDenoms = availableQuoteDenoms.value.filter(
        (token) => token.denom !== market.baseDenom
      );
      const quoteToken = tokens[market.baseDenom] ? [...tokens[market.baseDenom], ...filteredAvailableQuoteDenoms] : filteredAvailableQuoteDenoms;
      return {
        ...tokens,
        [market.quoteDenom]: baseTokens,
        [market.baseDenom]: quoteToken
      };
    }, {});
  });
  function getMarketsForQuoteDenom({
    baseTokenDenom,
    quoteTokenDenom
  }) {
    const existingMarket = spotStore.markets.find(
      ({ baseDenom, quoteDenom }) => {
        const foundBaseTokenDenom = [quoteDenom, baseDenom].includes(
          baseTokenDenom
        );
        const foundQuoteTokenDenom = [quoteDenom, baseDenom].includes(
          quoteTokenDenom
        );
        return foundBaseTokenDenom && foundQuoteTokenDenom;
      }
    );
    if (existingMarket) {
      return existingMarket;
    }
    const defaultMarket = spotStore.markets.find(
      ({ quoteDenom }) => [baseTokenDenom, quoteTokenDenom].includes(quoteDenom)
    );
    return defaultMarket || spotStore.markets[0];
  }
  return {
    tradableSlugMap,
    tradableTokensMap,
    getMarketsForQuoteDenom
  };
}
